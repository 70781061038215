import React from 'react';
import PropTypes from 'prop-types';
import { styled } from 'linaria/react';
import { media } from '../../styles/utils.ts';
import bgImg from '../../styles/assets/bgX.webp';

import Header from '../Header';
import SEO from './SEO';

const LayoutSection = styled.section`
  min-height: 100vh;
  position: relative;

  .bg {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background-image: url(${bgImg});
    background-position: bottom left;
    background-size: cover;
    background-repeat: repeat-x;
  }

  .main-content-wrapper {
    width: 100%;
    max-width: 970px;
    display: flex;
    justify-content: flex-end;
    padding: 20px 30px;

    &--menu-open {
      padding-top: 40px;
    }
  }
  .main-content {
    width: 100%;
    margin-top: 70px;
    max-width: 800px;
  }

  ${media.medium} {
    .main-content-wrapper {
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  ${media.desktop} {
    .main-content-wrapper {
      padding: 70px 50px;
    }
    .main-content {
      margin-top: 0px;
    }
    display: flex;
  }
`;

const Layout = (props) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const { children, title, description, seoImage, urlCanonical, page, withBgImage } = props;
  return (
    <LayoutSection>
      <SEO
        title={title}
        description={description}
        image={seoImage}
        url={urlCanonical}
      />
      {withBgImage && <div className="bg fadeIn2000" />}
      <Header
        page={page}
        title={title}
        className="main-header"
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      <div
        className={
          menuOpen
            ? 'main-content-wrapper main-content-wrapper--menu-open'
            : 'main-content-wrapper'
        }
      >
        <main className="main-content">{children}</main>
      </div>
    </LayoutSection>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
