export interface MediaI {
  medium: string;
  desktop: string;
  large: string;
}
export const breakpoints = {
  medium: 500,
  desktop: 930,
  large: 1440,
};

export const media = Object.keys(breakpoints).reduce((acc, item) => {
  // @ts-ignore
  acc[item] = `@media screen and (min-width: ${breakpoints[item]}px)`;
  return acc;
}, {}) as MediaI;

