import React from 'react';
import { Link } from 'gatsby';
import useTags from '../static_queries/useTags';

export default function TagsCloud() {
  const tags = useTags();
  return (
    <>
      {tags.map((tag) => {
        const url = `/blog/tag/${tag}`;
        return (
          <li className="navigation__list__item">
            <Link to={url} activeClassName="active">
              {tag}
            </Link>
          </li>
        );
      })}
    </>
  );
}
