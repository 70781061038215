import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { styled } from 'linaria/react';
import { IoIosMenu, IoMdCloseCircleOutline } from 'react-icons/io';
import TagsCloud from './TagsCloud';
import { media } from '../styles/utils.ts';
import Logo from '../styles/assets/bear.png';

export default function MainHeader({ menuOpen, setMenuOpen }) {
  const toggle = () => {
    const newValue = !menuOpen;
    localStorage.setItem('menuOpen', newValue);
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const restoredValue = !!localStorage.menuOpen;
    setMenuOpen(restoredValue);
  }, []);

  return (
    <Header className={menuOpen ? 'menuOpen' : ''}>
      <nav
        className={`navigation ${menuOpen ? 'menuOpen' : ''}`}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navigation__top">
          <div className="navigation__top__left">
            <Link to="/" className="navigation__logo__wrapper">
              <img src={Logo} alt="Logo" className="navigation__logo__image" />
            </Link>
            <h2 className="navigation__subtitle">
              made by{' '}
              <Link to="/info" className="highlight" activeClassName="active">
                aboutroots
              </Link>{' '}
            </h2>
          </div>
          <button
            onClick={toggle}
            className="navigation__menu-button"
            type="button"
          >
            {menuOpen ? (
              <IoMdCloseCircleOutline size="2rem" />
            ) : (
              <IoIosMenu size="2rem" />
            )}
          </button>
        </div>

        <ul className={`${menuOpen ? 'menuOpen' : ''} navigation__list`}>
          <li className="navigation__list__item separator">
            <Link to="/" activeClassName="active">
              all
            </Link>
          </li>
          <TagsCloud />
        </ul>
      </nav>
    </Header>
  );
}
const Header = styled.header`
  z-index: 100;
  padding: 15px 0 0;
  color: var(--black);
  --font-header: var(--font-heading);
  --header-bg: #f2f2f2ba;

  font-family: var(--font-header);
  background-color: var(--header-bg);

  width: 100%;
  position: fixed;
  .navigation {
    font-size: 15px;
    display: flex;
    flex-direction: column;

    a:hover {
      opacity: 1;
    }
    &__top {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      margin: 0 0 10px 0;
      &__left {
        display: flex;
        justify-content: space-between;
      }
    }
    &__logo__wrapper {
      margin-right: 20px;
    }
    &__subtitle {
      font-family: var(--font-header);
      font-weight: normal;
      font-size: 1rem;
      margin: 5px 20px 0 0;
    }
    &__menu-button {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      &:focus {
        outline: 0;
      }
    }
    &__logo__image {
      --logo-size-small: 32px;
      background-color: var(--black);
      height: var(--logo-size-small);
      width: var(--logo-size-small);
      min-width: var(--logo-size-small);
      border-radius: 50%;
      margin: 0;
    }
    &__list {
      display: none;
      &.menuOpen {
        display: flex;
        padding-top: 5px;
        background: #000;
        color: var(--header-bg);
        padding: 10px 1rem 0;
        overflow-x: scroll;
      }
    }
        &__list__item{
          margin-right: 1rem;
          &:last-of-type {
            margin-right: 5rem;
          }
          a {
            padding-bottom: 9px;
            border-bottom: 3px solid #000;
          }
          a.active {
            border-bottom: 3px solid var(--header-bg);
          }
        }
  }
  ${media.desktop} {
    padding: 70px 50px;
    position: relative; 
    width: 240px;
    .navigation {
      position: fixed;
      width: 180px;
      &__logo__wrapper {
        margin: 0 0 20px;
      }
      &__top {
        margin: 0;
        padding: 0 0 10px 0;
      }
      &__top__left {
        flex-direction: column;
        .highlight {
        color: var(--green);
        border-bottom: 1px solid var(--green);
        &.active {
          color: var(--red);
          border-color: var(--red);
        }
      }
      }

      &__menu-button {
        display: none;
      }
      &__logo__wrapper {
        margin-bottom: 15px;
      }
      &__logo__image {
        position: relative;
        padding: 0;
        left: -5px;
        --logo-size-big: 60px;
        height: var(--logo-size-big);
        width: var(--logo-size-big);
        min-width: var(--logo-size-big);
        border: none;
        border-radius: 12px;
        background: #F2F2F2;
        box-shadow:  6px 6px 12px #cecece, 
                    -6px -6px 12px #ffffff;
      }

      &__list, &__list.menuOpen {
        display: flex;
        background: none;
        color: var(--black);
        flex-direction: column
        margin: 0;
        padding: 0;
        overflow: visible;
      }
        &__list__item {
          display: block;
          margin: 0 0 4px 0;
          max-width: 140px;

          a {
            padding-bottom: 3px;
            border-bottom: 1px solid var(--header-bg);
          }
          a.active {
            border-bottom: 1px solid var(--red);
          }

          &.separator {
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid #e4dfdf;
          }
        }
    }
  }
`;
