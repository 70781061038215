import { graphql, useStaticQuery } from 'gatsby';

export default function useTags() {
  const data = useStaticQuery(graphql`
  query allTags {
    allTag(sort: {fields: value}) {
      distinct(field: value)
    }
  }
  
  `);
  return data.allTag.distinct;
}
